const CommingSoon = () => {
  return (
    <div className="coming-soon">
      <div>
        <img src="images/coming-soon.png" alt="" />
      </div>
    </div>
  );
};
export default CommingSoon;
