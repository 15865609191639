import { Routes } from "./Routes";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const router = Routes();
function App() {
  return (
    <>
    <ToastContainer />
    <RouterProvider router={router} />
    </>
  );
}

export default App;
