import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="header-top">
        <div className="container">
          <div className="h-t-row">
            <div className="h-t-col">
              <ul>
                <li>
                  <a href="#">Manage reservations</a>
                </li>
                <li>
                  <a href="#">Locations</a>
                </li>
                <li>
                  <a href="#">Accessibility</a>
                </li>
                <li>
                  <a href="#">Help</a>
                </li>
              </ul>
            </div>
            <div className="h-t-col">
              <ul>
                <li className="sl-icon">
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
                <li className="loc-search">
                  <div className="h-t-search">
                    <button>
                      <i className="far fa-search"></i>
                    </button>
                    <div className="input-box">
                      <input type="text" placeholder="Search..." />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="header-container">
          <div className="logo-col">
            <Link to="/">
              <img src="images/logo.png" alt="" />
            </Link>
          </div>

          <div className="nav-col">
            <nav id="cssmenu">
              <div className="button"></div>
              <ul className="core-menu">
                <li>
                  <Link to="/home" className="active">
                    Find A Hotel
                  </Link>
                </li>
                <li>
                  <Link to="/groups">Groups</Link>
                </li>
                <li>
                  <Link to="/deals">Deals</Link>
                </li>
                <li>
                  <Link to="/travel-tips">Travel Tips</Link>
                </li>
              </ul>
            </nav>
            {/* <div className="nav-r">
              <ul>
                <li>
                  <Link to="/sign-in">Sign In</Link>
                </li>
                <li>
                  <Link to="/sign-up" className="btn-fill">
                    Sign Up
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
