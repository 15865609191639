import { useFormik } from 'formik';
import Find from './Find';
import Banner from './Banner';
import Stay from './Stay';
import Business from './Business';
import Welcome from './Welcome';
import Explore from './Explore';
import Discover from './Discover';
import Travel from './Travel';
import Subscribe from './Subscribe';

const Main = () => {

  return (
    <>
      <Banner/>
      {/* <Find/> */}
      <Stay/>
      <Business/>
      <Welcome/>
      <Business/>
      <Explore/>
      <Discover/>
      <Travel />
      <Subscribe/>
    </>
  );
};
export default Main;
