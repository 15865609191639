import React from 'react'

const Subscribe = () => {
  return (
    <section className="SB-sec">
        <div className="container">
          <div className="SB-col">
            <div className="sb-left">
              <h4>Subscribe to our free weekly Booking Hotel newsletter!</h4>
            </div>
            <div className="sb-right">
              <input type="text" name="" placeholder="Your Email Address" />
              <button>Submit</button>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Subscribe