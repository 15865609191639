import React from 'react'

const Stay = () => {
  return (
    <section className="sec get-m-sec">
        <div className="container">
          <div className="sec-h">
            <h2>Get More from Your Stay</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="g-more">
                <div className="g-m-img">
                  <img src="images/get-more1.jpg" alt="" />
                </div>
                <div className="g-m-con">
                  <h4>
                    Join The Boardwalk Hotel Rewards
                    program
                  </h4>
                  <ul>
                    <li>Earn 10 points per $1 spent</li>
                    <li>Save with exclusive member rate</li>
                    <li>Elite members can earn bonus points</li>
                    <li>Get exclusive perks with Your Extras</li>
                  </ul>
                  <a href="#" className="l-more">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="g-more">
                <div className="g-m-img">
                  <img src="images/get-more2.jpg" alt="" />
                </div>
                <div className="g-m-con">
                  <h4>
                    Your stay just got more rewarding with
                    the boardwalk hotel
                  </h4>
                  <ul>
                    <li>Earn 10 points per $1 spent</li>
                    <li>Save with exclusive member rate</li>
                    <li>Elite members can earn bonus points</li>
                    <li>Get exclusive perks with Your Extras</li>
                  </ul>
                  <a href="#" className="l-more">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="g-more">
                <div className="g-m-img">
                  <img src="images/get-more3.jpg" alt="" />
                </div>
                <div className="g-m-con">
                  <h4>
                    Always Get an Exclusive Offer with
                    the boardwalk hotel
                  </h4>
                  <ul>
                    <li>Earn 10 points per $1 spent</li>
                    <li>Save with exclusive member rate</li>
                    <li>Elite members can earn bonus points</li>
                    <li>Get exclusive perks with Your Extras</li>
                  </ul>
                  <a href="#" className="l-more">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Stay
