import React from 'react'

const Business = () => {
    return (
        <section className="sec cl-sec">
            <div className="container">
                <div className="sec-h">
                    <h2>Your Business is Everywhere. Our Business is You</h2>
                </div>
                <ul className="o-client">
                    <li>
                        <a href="#">
                            <img src="images/cl1.png" alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images/cl2.png" alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images/cl3.png" alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images/cl4.png" alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images/cl5.png" alt="" />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img src="images/cl6.png" alt="" />
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default Business