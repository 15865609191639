import React from 'react'

const Welcome = () => {
    return (
        <section className="sec ab-sec">
            <div className="lf-img">
                <img src="images/ab-img.jpg" alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                        <div className="ab-con">
                            <h3>The Boardwalk Hotel Welcomes</h3>
                            <p>
                                the-boardwalk-hotel Welcomes you Hotels
                                International officially welcomes branson Hotels Americas to
                                the family. With the addition of more than 600 hotels, We now
                                offers more properties to visit in new destinations across the
                                U.S., Canada, the Caribbean and Latin America.
                            </p>
                            <a href="#" className="l-more">
                                Learn More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Welcome