const Banner = () => {
  return (
    <section className="banner-sec">
        <div className="banner-img">
          <img src="images/banner-img1.jpg" alt="" />
        </div>
        <div className="banner-caps">
          <h2>Lets Discover The best Place With Us</h2>
          <div className="b-offers">
            <div className="b-offers-top">
              <h4>booking early</h4>
              <span>Save up to </span>
            </div>
            <div className="b-offers-mid">
              <h4>15% OFF</h4>
            </div>
            <div className="b-offers-bot">
              <p>
                Book at least 3 days in advance to take advantage of the
                the-boardwalk-hotel advance purchase "Pay Now
                and Save" rate.
              </p>
              <span>
                <a href="#">Terms and conditions apply</a>
              </span>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Banner;
