import Main from "./UI/Main";
import CommingSoon from "./pages/CommingSoon";
import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";

export const Routes = () => {
  return createBrowserRouter([
    { path: "/", element: <CommingSoon /> },
    {
      path: "/home",
      element: <MainLayout />,
      children: [{ index: true, element: <Main /> }],
    },
    { path: "/groups", element: <CommingSoon /> },
    { path: "/deals", element: <CommingSoon /> },
    { path: "/travel-tips", element: <CommingSoon /> },
    { path: "/sign-in", element: <CommingSoon /> },
    { path: "/sign-up", element: <CommingSoon /> },
    { path: "/*", element: <CommingSoon /> },
  ]);
};
