import React from 'react'

const Travel = () => {
  return (
    <section className="sec TC-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3>
                Travel Connected with the new
                The Boardwalk Mobile App
              </h3>
              <p>Always the easiest way to get the lowest price. Guaranteed.</p>
              <ul>
                <li>Find hotels and reserve at the lowest rate</li>
                <li>
                  Review and redeem your the-boardwalk rewards
                </li>
                <li>Manage reservations while you're on the go</li>
              </ul>
            </div>
            <div className="col-md-6">
              <div className="TC-img">
                <h3>Scan to Download the App Today</h3>
                <a href="#">
                  <img src="images/qr-img.png" alt="" />
                </a>
                <ul>
                  <li>
                    <a href="#">
                      <img src="images/app-s-icon.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/g-play-icon.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Travel
