import React from 'react'

const Discover = () => {
  return (
    <section className="sec HD-sec">
        <div className="container">
          <div className="sec-h">
            <h2>Discover the Our Hotels difference</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="HD-icon">
                <img src="images/HD-icon1.png" alt="" />
              </div>
              <div className="HD-con">
                <h4>Lowest Price Guarantee</h4>
                <p>
                  Book direct on Website or the mobile app for the lowest price,
                  guaranteed.
                </p>
                <a href="#" className="s-rate">
                  See your Rate
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="HD-icon">
                <img src="images/HD-icon2.png" alt="" />
              </div>
              <div className="HD-con">
                <h4>1MM+ Guest Reviews</h4>
                <p>
                  Book direct on Websiteor the mobile app for the lowest price,
                  guaranteed.
                </p>
                <a href="#" className="s-rate">
                  See your Rate
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="HD-icon">
                <img src="images/HD-icon3.png" alt="" />
              </div>
              <div className="HD-con">
                <h4>Room to be Green</h4>
                <p>
                  Book direct on Website or the mobile app for the lowest price,
                  guaranteed.
                </p>
                <a href="#" className="s-rate">
                  See your Rate
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Discover