const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="logo-col">
              <a href="#">
                <img src="images/logo.png" alt="" />
              </a>
            </div>
            <p>
              <a href="#">+01647086161</a>
            </p>
            <p>
              <a href="#">theboardwalk@gmail.com</a>
            </p>
            <p>
              <a href="#">225 Jess Jo Pkwy Branson MO 65616</a>
            </p>
            <p className="sl-icon">
              <a href="#">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-youtube"></i>
              </a>
            </p>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <h4>Services</h4>
                <ul className="footer-nav">
                  <li>
                    <a href="#">Find a Hotel</a>
                  </li>
                  <li>
                    <a href="#">Locations</a>
                  </li>
                  <li>
                    <a href="#">Reservations</a>
                  </li>
                  <li>
                    <a href="#">Best Rate</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h4>Company</h4>
                <ul className="footer-nav f-right">
                  <li>
                    <a href="#">About Booking</a>
                  </li>
                  <li>
                    <a href="#">Careers</a>
                  </li>
                  <li>
                    <a href="#">Franchise Development</a>
                  </li>
                  <li>
                    <a href="#">Media Center</a>
                  </li>
                  <li>
                    <a href="#">Affiliates</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h4>Help</h4>
                <ul className="footer-nav f-right">
                  <li>
                    <a href="#">Customer Support</a>
                  </li>
                  <li>
                    <a href="#">Guest Feedback</a>
                  </li>
                  <li>
                    <a href="#">Sitemap</a>
                  </li>
                  <li>
                    <a href="#">Responsible Disclosure</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <p>All rights reserved Copyright &copy; 2023</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
