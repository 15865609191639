import React from 'react'

const Explore = () => {
  return (
    <section className="sec xp-sec">
        <div className="container">
          <div className="sec-h">
            <h2>Explore Best Experience</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="be-col">
                <div className="g-m-img">
                  <img src="images/BE-img1.jpg" alt="" />
                </div>
                <div className="g-m-con">
                  <h4>Now Get Bonus Points for New Members</h4>
                  <p>
                    Sign up for the-boardwalk-hotel Eat & Earn
                    and earn points for every dollar spent at 30,000+...
                  </p>
                  <a href="#" className="l-more">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="be-col">
                <div className="g-m-img">
                  <img src="images/BE-img2.jpg" alt="" />
                </div>
                <div className="g-m-con">
                  <h4>Unique Vacation Experiences</h4>
                  <p>
                    With Our Vacations, the official vacation ownership partner
                    of the-boardwalk-hotel, you can redeem your
                    points for spacious...
                  </p>
                  <a href="#" className="l-more">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="be-col">
                <div className="g-m-img">
                  <img src="images/BE-img3.jpg" alt="" />
                </div>
                <div className="g-m-con">
                  <h4>the Amenities Like A Home</h4>
                  <p>
                    Use and earn points for stays at 20+ hotels and resorts!
                    Enjoy slots, table games, pools, spas, dining...
                  </p>
                  <a href="#" className="l-more">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Explore
